import { AccountInfo } from '@azure/msal-browser';

class RolesService {
    private static _instance: RolesService;

    private constructor() {
        // Private constructor to prevent direct instantiation
    }
    public static get Instance(): RolesService {
        return this._instance || (this._instance = new this());
    }

    isAdmin(user: AccountInfo | null) {
        
        let isAdmin = false;        
        let roles = user?.idTokenClaims?.roles as String[];
        if (roles && roles.length) {
            isAdmin = roles.indexOf("RoomAdministrators") !== -1;
        }        
        return isAdmin;
    } 

  }

  export const rolesService = RolesService.Instance;