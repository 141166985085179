import { FormEvent, useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import QuestionLookup from "../../models/question-lookup";


const QuestionEditModal = (props: any) => {

    let show = props.show;
    let questionLookups: QuestionLookup[] = props.questionLookups ?? [];

    const [questionText, setQuestionText] = useState('');
    const [questionCode, setQuestionCode] = useState('');
    const [isMandatory, setIsMandatory] = useState(true);

    const handleClose = () => {
        props.close();
    };

    const handleEditSubmit = (event: FormEvent) => {
        event.preventDefault();
        event.currentTarget.parentElement?.classList.add('was-validated')
        let target = event.currentTarget.parentElement as any;

        if (target.checkValidity()) {
            props.editQuestion({
                questionText: questionText,
                code: questionCode,
                isMandatory: isMandatory
            })
        }
    };

    useEffect(() => {
        setQuestionText(props.selectedQuestion.questionText);        
        setQuestionCode(props.selectedQuestion.code);        
    }, [props.selectedQuestion]);

    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Question</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form id="editForm" className='needs-validation' noValidate >
                        <div className="form-group row mb-3">
                            <div className="col-sm-3">
                                <label htmlFor="roomName" className="col-form-label">Question Text</label>
                            </div>
                            <div className='col-sm-9'>
                                <input required id='roomName' type="text" className='form-control' placeholder="Question Text" value={questionText} onChange={evt => setQuestionText(evt.target.value)} />
                            </div>
                        </div> 
                        <div className="form-group row mb-3">
                            <div className="col-sm-3">
                                <label htmlFor="questionCode" className="col-form-label" >Code</label>
                            </div>
                            <div className='col-sm-9'>
                                <Form.Select required id='questionCode' className="form-control" defaultValue={props.selectedQuestion.code} onChange={evt => setQuestionCode(evt.target.value)}>
                                    <option key="default" value="">Choose...</option>
                                    {questionLookups.map(q => {
                                        return <option key={q.order} value={q.rowKey}>{q.rowKey}</option>
                                    })}
                                </Form.Select>
                            </div>
                        </div>
                        <Button variant="danger" type="submit" className="btn btn-primary float-end" onClick={(event) => handleEditSubmit(event)}>
                            Update
                        </Button>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default QuestionEditModal;