import { toast } from "react-toastify";

export const ErrorToast = () => {
    toast.error(()=> {
    return (<><p><b>Error</b><br/>If this continues please contact <a className="text-white" href="mailto:modality-development@nasstar.com?subject=Room Management Error">support</a>.</p></>) }
    , {
        position: "top-center",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });
};

export default ErrorToast;