import { useEffect, useState } from 'react';
import Tenant from '../../models/tenant';
import { useParams } from 'react-router-dom';

const TenantDropDown = (props: any) => {
  let basicTenantInfo: Tenant[] = props.items ?? [];
  let { id } = useParams();
  const [selected, setSelected] = useState('');
  const [loaded, setLoaded] = useState(false);

  const handleSelectionChanged = (selection: any) => {
    let rowKey = selection.currentTarget.value;
    setSelected(rowKey);
    props.onSelectionChanged(basicTenantInfo.find((i) => i.rowKey === rowKey));
  }

  useEffect(() => {
    if (!loaded) {
      if (basicTenantInfo.length > 0) {
        if (id) {
          let tenant = basicTenantInfo.find(i => i.rowKey === id);
          if (tenant) {
            setSelected(tenant.rowKey);
            props.onSelectionChanged(tenant);
          }
        }  
        setLoaded(true);
      }
    }
  }, []);

  return (
    <>
      <select className="form-select"
        value={selected}
        onChange={(event) => handleSelectionChanged(event)}
      >
         <option disabled label={"Please select a tenant"} />
        {
          basicTenantInfo.map((tenant, i) => {
            return (
              <option key={i} value={tenant.rowKey} label={tenant.adminPortalTitle} />
            )
          })};

      </select>
    </>
  );
};

export default TenantDropDown;