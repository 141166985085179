import {
    BrowserRouter as Router,
    Routes,
    Route
} from "react-router-dom";
import EditCreateRoomComponent from "./component/create-edit-room-page-components/edit-create-room-component";
import NotAuthorized from "./component/not-authorized";
import NotFoundPage from "./component/not-found-page";
import RegisterDomainComponent from "./component/register-domain-component";
import RoomManagementComponent from "./component/roommanagement-page-components/room-management-component";

function RoutesComponent() {
    return <Router>
        <Routes>
            <Route path="/" element={<RoomManagementComponent />} />
            <Route path="/:id" element={<RoomManagementComponent />} />
            <Route path="/create" element={<EditCreateRoomComponent />} />
            <Route path="/create/:id" element={<EditCreateRoomComponent />} />
            <Route path="/edit/:id/:roomUrl" element={<EditCreateRoomComponent />} />
            <Route path="/registerdomain/:id/:roomUrl" element={<RegisterDomainComponent />} />
            <Route path="/not-authorized" element={<NotAuthorized />} />
            <Route path="*" element={<NotFoundPage />} />
        </Routes>
    </Router>
}

export default RoutesComponent