import logo from "./img/logo.png";
import './App.css';
import { AuthenticatedTemplate, useMsal, UnauthenticatedTemplate } from '@azure/msal-react';
import Login from './component/login-component';
import Logout from './component/logout-component';
import 'react-toastify/dist/ReactToastify.css';

import { ToastContainer } from 'react-toastify';
import RoutesComponent from "./routes";

function App() {

    const {  accounts } = useMsal();
    const  activeAccount = accounts[0];    

    return (
        <>

            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
            <header className="no-nav">
                <AuthenticatedTemplate>
                    <div className="container">
                        <div className='row logo'>
                            <div className=" col-sm-6">
                                <img src={logo} alt="app-logo" />
                            </div>
                            <div className='col-sm-6'>
                                <div className=' float-end'>
                                    <p className="float-start">Welcome,  {activeAccount?.name}
                                        &ensp; <Logout />
                                    </p></div>
                            </div>
                        </div>
                    </div>
                </AuthenticatedTemplate>
            </header>
            <AuthenticatedTemplate>
                <main className="wb main-container" >
                    <section>
                        <div className="container-fluid">
                            <div className="screen-wrapper">
                                <section>
                                    <div className="container">
                                        <div className="col setup-form">
                                            <RoutesComponent></RoutesComponent>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </section>
                </main>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <main className="wb home">
                    <section className="welcome-screen">
                        <div className="container">
                            <div className="inner signin-box">
                                <img src={logo} alt="app-logo" className="logo"></img>
                                <p className="signin-text">Log in to see Room Management</p>
                                <Login />
                                <p className="description"></p>
                            </div>
                        </div>
                    </section>
                </main>
            </UnauthenticatedTemplate>
            <br /><br />
            <footer>
                <div className="text-center test">
                    <p>© Copyright 2023 Powered by Nasstar</p>
                </div>
            </footer>
        </>
    );
}

export default App;
