import { useMsal } from "@azure/msal-react";

const LogoutComponent = () => {
  const { instance } = useMsal();

  const handleLogoutRedirect = () => {
    instance.logoutRedirect().catch((error) => console.log(error));
   };

  return (
    <button className="btn btn-primary" onClick={() => handleLogoutRedirect()}>
      Log Out
    </button>
  );
};

export default LogoutComponent;
