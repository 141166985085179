import React, { useState, useEffect } from "react";

const Timer = (props: any) => {
  const [delay, setDelay] = useState(+props.duration);
  const minutes = Math.floor(delay / 60);
  const seconds = Math.floor(delay % 60);

  useEffect(() => {
    const timer = setInterval(() => {
      setDelay(delay - 1);
    }, 1000);

    if (delay === 0) {
      clearInterval(timer);
    }

    return () => {
      clearInterval(timer);
    };
  });  

  return (
    <>
      <br /><br /><br />
      <div>
        {
          seconds > 0 && localStorage.getItem("IsProcessing") !== "true" ? 
          ("Azure subdomain registration will start in...") : 
          ( "Azure subdomain registration is in progress...")
        }                
        {
          localStorage.getItem("IsProcessing") !== "true" && 
          <span>
            &emsp;{minutes}:{seconds}
          </span>
        }
      </div>
    </>
  );
};

export default Timer;