import axios from "axios";
import ErrorToast from "../component/error-toast-components/error-toast";

class AuthInterceptor {
  setAuthParams(getToken: any, logout: any) {
    axios.defaults.params = { getSilentToken: getToken, logout: logout }
  }

  async requestIntercept(config: any) {
    if (!axios.defaults.params.getSilentToken) {
      return config;
    }

    try {
      await axios.defaults.params.getSilentToken().then((data: any) => {
        config.headers['Authorization'] = `Bearer ${data.accessToken}`;
        config.headers['Ocp-Apim-Subscription-Key'] = process.env.REACT_APP_AZURE_API_KEY
        localStorage.setItem('access_token', data.accessToken);        
      })
        .catch(() => {
          //can't get token, user needs re-login
          window.alert("For security reasons, you have been logged out due to inactivity. Please log in again.");
          axios.defaults.params.logout({ logoutParams: { returnTo: window.location.origin } });
        });

    } catch (e) {
      console.log(e);
    }
    return config;
  }
}

export const authInterceptor = new AuthInterceptor();
axios.interceptors.response.use((response) => {
  if (response.status === 401) {
    window.location.href = '/not-authorized';
  }
  return response;
}, (error) => {
  if (error.response.status == 500) {
    ErrorToast();
  }
  if (error.response && error.response.data) {
    if (error.response.status === 401) {
      window.location.href = '/not-authorized';
    }
    return Promise.reject(error.response.data);
  }
  if (error.response.status === 401) {
    window.location.href = '/not-authorized';
  }
  return Promise.reject(error.message);
});
axios.interceptors.request.use(authInterceptor.requestIntercept);