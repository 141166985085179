import { useEffect, useRef } from 'react';

export function UseInterval(callback: Function, delay: any, pollCount: any, disabled: boolean) {
  const savedCallback = useRef(callback); 
  let currentCount = 0;   

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {  
      currentCount++;        
      if (!disabled && currentCount < pollCount) {
        savedCallback.current();
      }    
    }
    if (delay !== null) { 
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }    
  }, [callback, delay, disabled]);

}


