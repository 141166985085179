import Question from "../../models/question";
import { useState, useEffect } from 'react';
import QuestionAddModal from "./question-add-modal";
import QuestionRemoveModal from "./question-remove-modal";
import QuestionEditModal from "./question-edit-modal";
import QuestionLookup from "../../models/question-lookup";


const QuestionComponent = (props: any) => {
    let questions: Question[] = props.questions ?? [];

    const [showEditPopUp, setShowEditPopUp] = useState(false);
    const [showRemovePopUp, setShowRemovePopUp] = useState(false);
    const [showAddPopUp, setShowAddPopUp] = useState(false);
    const [selectedQuestion, setSelectedQuestion] = useState({} as Question);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [hasDuplicateCodes, setHasDuplicateCodes] = useState(false);

    const editQuestion = (question: Question) => {
        selectedQuestion.isMandatory = question.isMandatory;
        selectedQuestion.questionText = question.questionText;
        selectedQuestion.code = question.code;
        setShowEditPopUp(false);
        validateQuestions();
    };

    const removeQuestion = (question: Question) => {
        props.removeQuestion(question);
        setShowRemovePopUp(false);
        validateQuestions();
    };

    const addQuestion = (question: Question) => {
        props.addQuestion(question);
        setShowAddPopUp(false);
        validateQuestions();
    };

    const validateQuestions = () => {
        let questionCodes = questions.map(q => q.code);
        let questionLookupCodes = props.questionLookups.map((q: QuestionLookup) => q.rowKey);
        if (new Set(questionCodes).size !== questions.length || // Checking for duplicates using sets
            !questionCodes.every(q => questionLookupCodes.indexOf(q) >= 0)) { 
                setHasDuplicateCodes(true);
        }
        else {
            setHasDuplicateCodes(false);
        }
    };

    const openEditModal = (value: boolean, index: number) => {
        setSelectedIndex(index);
        setSelectedQuestion(questions[index]);
        setShowEditPopUp(value);
    };

    const openRemoveModal = (value: boolean, index: number) => {
        setSelectedIndex(index);
        setSelectedQuestion(questions[index]);
        setShowRemovePopUp(value);
    };

    const closeEditModal = (value: boolean) => {
        setShowEditPopUp(false);
    };

    const closeRemoveModal = (value: boolean) => {
        setShowRemovePopUp(false);
    };

    const openAddModal = (value: boolean) => {
        setShowAddPopUp(value);
    };

    useEffect(() => {
        validateQuestions();
    }, [props.questions, props.questionLookups]);

    return (
        <>
            <h4 id="Questions">Questions</h4>
            <p hidden={questions.length > 0} className="text-danger">At least 1 Question is required...</p>
            <label htmlFor="hasDuplicateCodes" hidden={!hasDuplicateCodes} className="text-danger">Please ensure used codes are unique...</label>
            <div className='row'>
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col" className="col-sm-6">Question Text</th>                            
                            <th scope="col" className="col-sm-2"><span>Code</span></th>
                            <th scope="col" className="col-sm-2"><span>Actions</span></th>
                            <th scope="col">
                                <button disabled={props.disabled} type="button" className="btn btn-primary float-end" onClick={value => openAddModal(true)}>Add Question</button>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {questions.map((row, i) => {
                            return (
                                <tr key={i}>
                                    <td>{row.questionText}</td>
                                    <td>{row.code}</td>
                                    <td>  <button disabled={props.disabled} type="button" className="btn btn-outline-primary" onClick={() => openEditModal(true, i)}>Edit</button>
                                        <button disabled={props.disabled} type="button" className="btn btn-outline-danger" onClick={() => openRemoveModal(true, i)}>Remove</button></td>
                                    <td></td>
                                </tr>)
                        })}
                    </tbody>
                </table>
            </div>
            <div className='row'>
                <div className="mb-3 col-sm d-grid gap-2">
                    
                </div>
            </div>
            <QuestionAddModal show={showAddPopUp} questionLookups={props.questionLookups} close={() => openAddModal(false)} addQuestion={(value: Question) => addQuestion(value)} />
            <QuestionEditModal show={showEditPopUp} questionLookups={props.questionLookups} close={() => closeEditModal(false)} editQuestion={(value: Question) => editQuestion(value)} index={selectedIndex} selectedQuestion={selectedQuestion} />
            <QuestionRemoveModal show={showRemovePopUp} questionLookups={props.questionLookups} close={() => closeRemoveModal(false)} removeQuestion={(value: Question) => removeQuestion(value)} index={selectedIndex} selectedQuestion={selectedQuestion} />
        </>
    );
};

export default QuestionComponent;