import { useMsal } from "@azure/msal-react";



const LoginComponent = () => {
  const { instance } = useMsal();

  const handleLoginRedirect = () => {
    instance.loginRedirect({scopes : []}).catch((error) => console.log(error));
  }

    return <div>
      <button className="loginBtn btn--lg" onClick={() => handleLoginRedirect()}>
        <i className="icon-windows"></i>Log In
      </button>
    </div>;
  };



  export default LoginComponent;