import { FormEvent, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";


const RoomRemoveModal = (props: any) => {

    let show = props.show;

    const [roomText, setRoomText] = useState('');    

    const handleClose = () => {
        props.close();
    };

    const handleRemoveSubmit = (event: FormEvent) => {
        event.preventDefault();
        event.currentTarget.parentElement?.classList.add('was-validated')
        let target = event.currentTarget.parentElement as any;       
        
        props.removeRoom(
            {
                partitionKey: props.selectedRoom.partitionKey,
                rowKey: props.selectedRoom.rowKey                 
            })
    };

    useEffect(() => {
        setRoomText(props.selectedRoom.name);        
    }, [props.selectedRoom]);

    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Room</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form id="removeForm" className='needs-validation' noValidate >
                        <div className="row">
                            <div className="col-sm">
                                <div className="mb-3 form-group row">
                                    <label className="col-form-label col-sm-3">Room Name:</label>
                                    <div className='col-sm-9 '>
                                        <label className="col-form-label">{roomText}</label>
                                    </div>
                                </div>
                                <div className="mb-3 form-group row">
                                    <label className="col-form-label col-sm-3">Room URL:</label>
                                    <div className='col-sm-9 '>
                                        <label className="col-form-label">{props.selectedRoom.rowKey}</label>
                                    </div>
                                </div>
                                <div className="mb-3 form-group row">
                                    <label className="">Are you sure you want to delete this room ?</label>
                                </div>
                            </div>
                        </div>
                        <Button variant="danger" type="submit" className="btn btn-primary float-end" onClick={(event) => handleRemoveSubmit(event)}>
                            Delete
                        </Button>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default RoomRemoveModal;