const RoomStatus = (props: any) => {

    switch (props.status) {
        case "Created":
        case "EasyDnsDone":
            return <>
                <div className="circle bg-danger"></div>
            </>
        case "AzureRegistrationDone":
        default:
            return <>
                <div className="circle bg-success"></div>
            </>
    }

}

export default RoomStatus