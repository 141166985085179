import { useState, useEffect } from 'react';
import { UseInterval } from '../hooks/useInterval-hook';
import { useLocation, useParams, Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { SERVICE_BASE } from '../globals';
import axios from 'axios';
import Timer from '../hooks/timer';

const RegisterDomainComponent = () => {

  let [isRegistered, setIsRegistered] = useState(false);
  const [roomStatus, setRoomStatus] = useState('');
  const [isRunning, setIsRunning] = useState(true);
  const [delay] = useState(20000); //miliseconds
  const [retryCount] = useState(10);
  const [isCancelled, setIsCancelled] = useState(false);
  const Created = "Created";
  const EasyDnsDone = "EasyDnsDone";
  const AzureRegistrationDone = "AzureRegistrationDone";
  const location = useLocation();
  const navigate = useNavigate();
  let { id } = useParams();
  let { roomUrl } = useParams();

  const retryRegistration = () => {
    setIsCancelled(false);
    setIsRunning(true);
  }

  const cancelRegistration = () => {
    setIsCancelled(true);
    setIsRunning(false);
  }

  const pauseRetryCounter = () => {
    setIsRunning(false); 
    localStorage.setItem("IsProcessing", "true");
  }

  const resumeRetryCounter = () => {
    setIsCancelled(true);
    setIsRunning(false);
  }

  const registrationRequired = () => {
    return roomStatus === EasyDnsDone && 
           location.state.data.createAndRegisterDomains && 
           !isRegistered && 
           !isCancelled && 
           localStorage.getItem("IsProcessing") !== "true"
  };

  const goBack = () => {
    navigate("/" + id);
  };

  useEffect(() => {
    setRoomStatus(location.state.data.roomStatus);
    let reg = roomStatus === AzureRegistrationDone || roomStatus === "null";
    setIsRegistered(reg);
  }, [location.state.data.roomStatus, roomStatus]);

  UseInterval(async () => {
    if (registrationRequired()) {
      pauseRetryCounter();
      let postPromise = axios.post(`${SERVICE_BASE}/RoomManagement/RegisterWithAzure?id=${id}&roomUrl=${roomUrl}`, { id: id, roomUrl: roomUrl }).then(result => {
        setIsRegistered(result.data);
        if (result.data === true) {
          navigate("/registerdomain/" + id + "/" + roomUrl, { state: { data: { roomStatus: AzureRegistrationDone, createAndRegisterDomains: location.state.data.createAndRegisterDomains } } });
        }
        else {
          resumeRetryCounter();
          toast.error("Problem registering with Azure. Retry and if still having problem, go Back. After an hour, navigate to this page and try again.");
        }
      })
      .catch(() => {
        resumeRetryCounter();
        toast.error("Problem while registering with Azure. Retry and if still having problem, go Back, after an hour, navigate to this page and try again.");
      })
      .finally(() => { 
        localStorage.removeItem("IsProcessing"); 
      });
      toast.promise(
        postPromise,
        {
          pending: {
            render() {
              return "Registration is in progress"
            },
            icon: true,
          }
        }
      )
    }
  }, isRunning ? delay : null, retryCount, isRegistered);

  const easyDnsRegistarion = () => {
    const promise = axios.post(`${SERVICE_BASE}/RoomManagement/RegisterWithEasyDns?id=${id}&roomUrl=${roomUrl}`, { id: id, roomUrl: roomUrl })
      .then((result) => {
        if (result.data) {
          //EasyDns done, start Azure Registration counter
          navigate("/registerdomain/" + id + "/" + roomUrl, { state: { data: { roomStatus: EasyDnsDone, createAndRegisterDomains: location.state.data.createAndRegisterDomains } } });
        } else {
          toast.error("Problem while registering with EasyDns, try after some time.");
          setTimeout(() => { goBack(); }, 5000);
        }
      });
    toast.promise(
      promise,
      {
        pending: {
          render() {
            return `EasyDns Registration in progress`
          }
        },
        error: {
          render() {
            return `Unable to register with EasyDns. 
                    Please try again after some time.`
          }
        }
      }
    )
  }

  return (<>
    <div className="col-sm-6 intro">
      <h3>Room Management</h3>
    </div>
    <Link className="btn btn-primary float-end" to={"/" + id}>Back</Link>
    <div>Room:  {roomUrl}</div>
    {roomStatus === Created ? (
      <div>
        <br />
        <button className="btn btn-primary" onClick={() => easyDnsRegistarion()}>Register With EasyDns</button>
      </div>
    ) : (
      <div>
        {isRegistered ? (
          <div>
            <br /><br /><br />Azure subdomain registration is done.
            <p>The room is ready for consultation.</p>
            <p>Room Url: <a href={"https://" + roomUrl} target="_">https://{roomUrl}</a></p>
          </div>
        ) : (
          <div> {isCancelled ? (
            <div>
              <br /><br /><br />Press Retry to trigger Azure subdomain registration
              <p><button className="btn btn-primary" onClick={() => retryRegistration()}>Retry</button></p>
            </div>
          ) : (
            <div>
              <Timer duration={delay / 1000}></Timer>
              <button disabled={!isRunning} className="btn btn-primary" onClick={() => cancelRegistration()}>Cancel</button>
            </div>
          )}
          </div>
        )}
      </div>

    )}
  </>)
};

export default RegisterDomainComponent;