

const NotFoundPage = () => {
  return (
    <div className="content-layout">
        <h1 id="page-title" className="content__title">
          Page Not Found
        </h1>
    </div>
  );
};

export default NotFoundPage;