import axios from "axios";
import { SERVICE_BASE } from "../globals";
import BasicRoomInfo from "../models/basic-room-info";
import Tenant from "../models/tenant";
import RoomDefaults from "../models/room-defaults";
import QuestionLookup from "../models/question-lookup";
import Room from "../models/room";

class ApiService {

    async getPossibleRooms(tenantId: string) {
        return await axios.get<BasicRoomInfo[]>(`${SERVICE_BASE}/RoomManagement/BasicRoomInfo?tenantId=${tenantId}`);
    };

    async getTennants() {
        return await axios.get<Tenant[]>(`${SERVICE_BASE}/Tenants`);
    };

    async getFeatureFlag() {
        return await axios.get<boolean>(`${SERVICE_BASE}/RoomManagement/FeatureFlag`);
    };

    async getQuestionLookups() {
        return await axios.get<QuestionLookup[]>(`${SERVICE_BASE}/RoomManagement/QuestionLookups`);
    };

    async getRoomDefaults(id: string) {
        return await axios.get<RoomDefaults>(`${SERVICE_BASE}/RoomManagement/Defaults?id=${id}`);
    };

    async getRoom(id: string, roomUrl: string) {
        return await axios.get<Room>(`${SERVICE_BASE}/RoomManagement?id=${id}&roomUrl=${roomUrl}`);
    };

    async putRoom(model: Room) {
        return await axios.put(`${SERVICE_BASE}/RoomManagement`, model)
    }

    async postRoom(model: Room) {
        return await axios.post(`${SERVICE_BASE}/RoomManagement`, model)
    }

    async deleteRoom(id: string, roomUrl: string) {
        return await axios.delete(`${SERVICE_BASE}/RoomManagement?id=${id}&roomUrl=${roomUrl}`);
    };
}

export const apiService = new ApiService();