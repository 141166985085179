const NotAuthorized = () => {
    return (
        <div className="content-layout">
            <h1 id="page-title" className="content__title text-center">
                Not Authorized!
            </h1>
            <p id="page-title" className="content__title text-center">
                You do not currently have permission to view this page.<br />
            </p>
        </div>
    );
};

export default NotAuthorized;