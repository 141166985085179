import { FormEvent, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import QuestionLookup from "../../models/question-lookup";


const QuestionAddModal = (props: any) => {

    let show = props.show;
    let questionLookups: QuestionLookup[] = props.questionLookups ?? [];

    const [questionText, setQuestionText] = useState('');
    const [questionCode, setQuestionCode] = useState('');
    const [isMandatory, setIsMandatory] = useState(true);

    const handleClose = () => {
        props.close();
    };

    const handleAddSubmit = (event: FormEvent) => {
        event.preventDefault();
        event.currentTarget.parentElement?.classList.add('was-validated')
        let target = event.currentTarget.parentElement as any;

        if (target.checkValidity()) {
            props.addQuestion({
                questionText: questionText,
                code: questionCode,
                isMandatory: isMandatory
            })

            setQuestionText('');
            setQuestionCode('');
        }
    };


  return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add New Question</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form id="addForm" className='needs-validation' noValidate >
                        <div className="form-group row mb-3">
                            <div className="col-sm-3">
                                <label htmlFor="roomName" className="col-form-label">Question Text</label>
                            </div>
                            <div className='col-sm-9'>
                                <input required id='roomName' type="text" className='form-control' placeholder="Question Text" value={questionText} onChange={evt => setQuestionText(evt.target.value)} />
                            </div>
                        </div>
                        <div className="form-group row mb-3">
                            <div className="col-sm-3">
                                <label htmlFor="questionCode" className="col-form-label" >Code</label>
                            </div>
                            <div className='col-sm-9'>
                                <Form.Select required id='questionCode' className="form-control" onChange={evt => setQuestionCode(evt.target.value)}>
                                    <option key="default" value="">Choose...</option>
                                    {questionLookups.map(q => {
                                        return <option key={q.order} value={q.rowKey}>{q.rowKey}</option>
                                    })}
                                </Form.Select>
                            </div>
                        </div>
                        <Button variant="primary" type="submit" className="btn btn-primary float-end" onClick={(event) => handleAddSubmit(event)}>
                            Add
                        </Button>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default QuestionAddModal;