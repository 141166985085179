import { useEffect } from "react";
import { authInterceptor } from "./auth-interceptor";
import { useMsal } from '@azure/msal-react';
import { AUTH_CONFIG } from "../globals";


function AuthInject() {
  const { instance } = useMsal();    
  const activeAccount = instance.getAllAccounts()[0];
  const accessTokenRequest = {
    scopes: [`${AUTH_CONFIG.scope}`],
    account: activeAccount,
  };

  const getToken = async () => {
    const account = instance.getAllAccounts()[0];
    return instance.acquireTokenSilent(accessTokenRequest)              
  }  

  useEffect(() => {
    authInterceptor.setAuthParams(getToken, instance.logout);
  }, [getToken, instance.logout]);

  return null;
}

export default AuthInject