import { useState, useEffect, FormEvent } from 'react';
import { Link, useParams, useNavigate } from "react-router-dom";
import Room from '../../models/room';
import Question from '../../models/question';
import QuestionLookup from '../../models/question-lookup';
import AdminDomain from '../../models/admin-domain';
import GroupSessionsDomain from '../../models/group-sessions-domain';
import { toast } from 'react-toastify';
import QuestionComponent from './question-component';
import { apiService } from '../../services/api-services';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const EditCreateRoomComponent = () => {
  let { id } = useParams();
  let { roomUrl } = useParams();
  let domain = '.oneconsultation.net';
  let createRoomStatus = 'Created';
  let feedbackUrlPrefix = 'https://';
  let defaultBeginConsultationText = 'Begin Consultation';
  let defaultGroupRoomText = 'Join Group Session';
  let easyDnsRoomStatus = "EasyDnsDone";
  let azureRegistrationDone = "AzureRegistrationDone";
  let configPartitionKey = "config";
  let backendMeetingPlatformValue = "teams";
  let internalConfigPartitionKey = "internalconfig";

  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);
  const [editMode, setEditMode] = useState(roomUrl ? true : false);
  const [processing, setProcessing] = useState(false);

  const [roomName, setRoomName] = useState('');
  const [url, setUrl] = useState('');
  const [securityGroup, setSecurityGroup] = useState('');

  const [showGroups, setShowGroups] = useState(false);
  const [isAuditorium, setIsAuditorium] = useState(false);
  const [isShowPatientName, setIsShowPatientName] = useState(false);

  const [feedbackUrl, setFeedbackUrl] = useState('');
  const [serviceTitle, setServiceTitle] = useState('');
  const [waitingRoomLayout, setWaitingRoomLayout] = useState('');
  const [recordingMessage, setRecordingMessage] = useState('');
  const [privacyPolicyLink, setPrivacyPolicyLink] = useState('');
  const [roomImageUrl, setRoomImageUrl] = useState('');
  const [introductoryText, setIntroductoryText] = useState('');
  const [hideChat, setHideChat] = useState(false);
  const [showConfirmableMessage, setShowConfirmableMessage] = useState(true);
  const [confirmableMessageText, setConfirmableMessageText] = useState('');
  const [captureIP, setCaptureIP] = useState(true);
  const [roomOnline, setRoomOnline] = useState(true);

  const [urlPrefix, setUrlPrefix] = useState('');
  const [teamsMeetingOrganiserId, setTeamsMeetingOrganiserId] = useState('');
  const [backEndMeetingPlatform, setBackEndMeetingPlatform] = useState('');
  const [azureWebAppName, setAzureWebAppName] = useState('');

  const [questions, addQuestions] = useState<Question[]>([]);
  const [questionLookups, setQuestionLookups] = useState<QuestionLookup[]>([]);

  const [roomStatus, setRoomStatus] = useState<undefined | string>(undefined);
  const [beginConsultationText, setBeginConsultationText] = useState<string>('');
  const [registrationFeatureFlag, setRegistrationFeatureFlag] = useState(false);

  const removeQuestion = (question: Question) => {
    questions.forEach((item, index) => {
      if (item.questionText === question.questionText) {
        questions.splice(index, 1);
      }
    });
  };

  const getDefaults = async () => {
    if (id) {
      await apiService.getRoomDefaults(id).then(result => {
        let roomDefaults = result.data;

        if (roomDefaults.inConsultationRecordingLink) {
          setPrivacyPolicyLink(roomDefaults.inConsultationRecordingLink);
        }
        if (roomDefaults.inConsultationRecordingMessage) {
          setRecordingMessage(roomDefaults.inConsultationRecordingMessage);
        }
        if (roomDefaults.portalLogoUrl) {
          setRoomImageUrl(roomDefaults.portalLogoUrl);
        }
        if (roomDefaults.consultationWaitingRoomLayout) {
          setWaitingRoomLayout(roomDefaults.consultationWaitingRoomLayout);
        }
        if (roomDefaults.urlPrefix) {
          setUrlPrefix(roomDefaults.urlPrefix);
        }
        if (roomDefaults.teamsMeetingOrganiserId) {
          setTeamsMeetingOrganiserId(roomDefaults.teamsMeetingOrganiserId)
        }
        if (roomDefaults.portalIntroExplainingText) {
          setIntroductoryText(roomDefaults.portalIntroExplainingText)
        }
        if (roomDefaults.confirmableMessageText) {
          setConfirmableMessageText(roomDefaults.confirmableMessageText);
        }

        setBeginConsultationText(defaultBeginConsultationText);
      });
    }
  };


  const goBack = () => {
    navigate("/" + id);
  };

  const getRoom = async () => {
    if (id && roomUrl) {
      await apiService.getRoom(id, roomUrl).then(result => {
        let room = result.data;
        let subdomain = editMode ? room.internalConfig.rowKey.replace(domain, '') : room.internalConfig.rowKey.replace(room.adminConfig.urlPrefix + "-", '').replace(domain, '');

        setUrlPrefix(room.adminConfig.urlPrefix);

        if (room?.adminDomain) {
          setRoomName(room.adminDomain.name);
          setSecurityGroup(room.adminDomain.restrictedGroupID);
        } else if (room.groupSessionsDomain) {
          setRoomName(room.groupSessionsDomain?.groupSessionName);
          setSecurityGroup(room.groupSessionsDomain.restrictedGroupID);
        }

        setUrl(subdomain);

        if (room.internalConfig.teamsMeetingOrganiserId) {
          setTeamsMeetingOrganiserId(room.internalConfig.teamsMeetingOrganiserId)
        }

        if (room.internalConfig.backEndMeetingPlatform) {
          setBackEndMeetingPlatform(room.internalConfig.backEndMeetingPlatform);
        }

        if (room.internalConfig.azureWebAppName) {
          setAzureWebAppName(room.internalConfig.azureWebAppName);
        }

        setIsAuditorium(room.config.isAuditorium);
        setShowGroups(room.config.isGroup);
        if (room.groupSessionsDomain) {
          setIsShowPatientName(room.groupSessionsDomain.isShowPatientName);
        }
        setShowGroups(room.config.isGroup);

        setFeedbackUrl(room.config.disconnectURL);
        setServiceTitle(room.config.portalTitle);
        setWaitingRoomLayout(room.config.consultationWaitingRoomLayout);
        setRecordingMessage(room.config.inConsultationRecordingMessage);
        setPrivacyPolicyLink(room.config.inConsultationRecordingLink);
        setRoomImageUrl(room.config.portalLogoUrl);
        setIntroductoryText(room.config.portalIntroExplainingText);
        setHideChat(room.config.hideChat);
        setShowConfirmableMessage(room.config.showConfirmableMessage);
        setConfirmableMessageText(room.config.confirmableMessageText);
        setCaptureIP(room.config.showUserIPAddress)
        setRoomOnline(room.config.isOnline)
        
        addQuestions(room.questions ?? []);
        setRoomStatus(room.config.roomStatus);
        setBeginConsultationText(room.config.beginConsultationText ? room.config.beginConsultationText : defaultBeginConsultationText);
      });
    }
  };

  const groupsChanged = async (value: boolean) => {
    if (!value) {
      setIsAuditorium(false);
    }
    setShowGroups(value);
    if (!editMode) { value ? setBeginConsultationText(defaultGroupRoomText) : setBeginConsultationText(defaultBeginConsultationText); }
  };

  const auditoriumChanged = async (value: boolean) => {
    if (!value) {
      setIsShowPatientName(false);
    }
    setIsAuditorium(value);
  };

  const confirmableMessageChanged = async (value: boolean) => {
    setShowConfirmableMessage(value);
  }

  const saveRoom = async () => {
    let model: Room = {
      adminConfig: {
        rowKey: id ?? '',
        adminPortalTitle: serviceTitle,
        adminPortalLogoUrl: '',
        urlPrefix: urlPrefix
      },
      config: {
        partitionKey: configPartitionKey,
        rowKey: rebuildUrl(),
        consultationWaitingRoomLayout: waitingRoomLayout,
        disconnectURL: feedbackUrl ? feedbackUrl : feedbackUrlPrefix + rebuildUrl(),
        inConsultationRecordingLink: privacyPolicyLink,
        inConsultationRecordingMessage: recordingMessage,
        isAuditorium: isAuditorium,
        isGroup: showGroups,
        portalIntroExplainingText: introductoryText,
        portalLogoUrl: roomImageUrl,
        portalTitle: serviceTitle,
        groupRoomName: showGroups ? roomName : undefined,
        roomStatus: editMode ? roomStatus : createRoomStatus,
        beginConsultationText: beginConsultationText,
        hideChat: hideChat,
        showConfirmableMessage: showConfirmableMessage,
        confirmableMessageText: confirmableMessageText,
        showUserIPAddress: captureIP,
        isOnline: roomOnline
      },
      internalConfig: {
        rowKey: rebuildUrl(),
        partitionKey: internalConfigPartitionKey,
        teamsMeetingTenantId: id,
        teamsMeetingOrganiserId: teamsMeetingOrganiserId,
        backEndMeetingPlatform: backendMeetingPlatformValue,
        azureWebAppName: azureWebAppName
      },
      questions: showGroups ? [] : questions

    };

    if (showGroups || isAuditorium) {
      let groupSessionsDomain: GroupSessionsDomain = {
        partitionKey: id ?? '',
        rowKey: rebuildUrl(),
        groupSessionName: roomName,
        restrictedGroupID: securityGroup,
        isShowPatientName: isAuditorium ? false : isShowPatientName
      };

      model.groupSessionsDomain = groupSessionsDomain;

    } else {
      let adminDomain: AdminDomain = {
        partitionKey: id ?? '',
        rowKey: rebuildUrl(),
        name: roomName,
        restrictedGroupID: securityGroup
      };

      model.adminDomain = adminDomain;
    }

    if (id) {
      const promise = editMode ? apiService.putRoom(model) : apiService.postRoom(model);
      promise.then((data) => {
        if (!editMode && data?.data.roomStatus === easyDnsRoomStatus) {
          navigate("/registerdomain/" + id + "/" + model.config.rowKey, { state: { data: data?.data } })
        } else if (!editMode && data?.data.roomStatus === azureRegistrationDone) {
          navigate("/registerdomain/" + id + "/" + model.config.rowKey, { state: { data: { roomStatus: azureRegistrationDone } } });
        } else {
          setTimeout(() => { goBack(); }, 5000);
        }
      });
      toast.promise(
        promise,
        {
          pending: {
            render() {
              return `${editMode ? 'Saving room' : 'Creating room'}`
            },
            icon: true,
          },
          success: {
            render() {
              let editMessage = 'Your room is being updated.'
              return `${editMode ? editMessage : 'Your room is being created. Check back to see when its completed.'}`
            },
            // other options
            icon: "🟢",
          },
          error: {
            render({ data }) {
              // When the promise reject, data will contains the error
              setProcessing(false);
              let error = (data as any).RowKey?.[0] ?? (data as any)?.message;
              return `${error}`;
            }
          }
        });
    }
  }

  const rebuildUrl = () => {
    if (urlPrefix && !editMode) {
      return urlPrefix + "-" + url.toLowerCase() + domain;
    }

    return url.toLowerCase() + domain;
  }

  const hideUrlPrefix = () => {
    return !urlPrefix || editMode;
  }

  const scrollToInvalid = (form: HTMLFormElement) => {
    const invalidInputs = Array.from<HTMLElement>(form.querySelectorAll(':invalid'));
    invalidInputs.sort((a: any, b: any) => a.getBoundingClientRect().top - b.getBoundingClientRect().top);
    invalidInputs[0].scrollIntoView({ block: 'center', behavior: 'smooth' });
  }

  const handleSubmit = (event: FormEvent) => {

    event.preventDefault();
    event.currentTarget.classList.add('was-validated')
    let target = event.currentTarget as HTMLFormElement
    if (target.checkValidity()) {
      if (!showGroups && questions.length === 0) {
        return;
      }
      let questionCodes = questions.map(q => q.code);
      let questionLookupCodes = questionLookups.map(q => q.rowKey);
      if (new Set(questionCodes).size !== questions.length || // Checking for duplicates using sets
        !questionCodes.every(q => questionLookupCodes.indexOf(q) >= 0)) {
        return;
      }
      setProcessing(true);
      saveRoom();
    } else {
      setProcessing(false);
      scrollToInvalid(target);
    }
  }

  useEffect(() => {
    if (!loaded) {
      let promise = roomUrl ? getRoom() : getDefaults();
      promise.then(() => {
        apiService
          .getFeatureFlag()
          .then(result => setRegistrationFeatureFlag(result.data))
      }).then(() => {
        apiService
          .getQuestionLookups()
          .then(result => setQuestionLookups(result.data))
      }).finally(() => setLoaded(true));;
    }
  });

  if (!loaded) {
    return <div className="loader"></div>
  }

  return (
    <>
      <div className="col-sm-6 intro">
        <h3>Room Management</h3>
      </div>
      {registrationFeatureFlag && (roomStatus === createRoomStatus || roomStatus === easyDnsRoomStatus) ?
        (
          <>
            {navigate("/registerdomain/" + id + "/" + roomUrl, { state: { data: { createAndRegisterDomains: registrationFeatureFlag, roomStatus: roomStatus } } })}
          </>
        ) :
        (
          <>
            <div className="intro row">
              <div className='col-sm float-end'>
                <Link className="btn btn-primary float-end" to={"/" + id}>Back</Link>
              </div>
            </div>
            <form onSubmit={evt => handleSubmit(evt)} className='needs-validation' noValidate  >
              <div className="row">
                <div className="col-sm">
                  <h4>Room info</h4>
                  <div className="mb-3 form-group row">
                    <label htmlFor="roomName" className="col-form-label col-sm-3">Room Name:</label>
                    <div className='col-sm-9 '>
                      <input disabled={processing} required={true} id='roomName' type="text" className='form-control' placeholder="Room Name" value={roomName} onChange={evt => setRoomName(evt.target.value)} />
                    </div>
                  </div>
                  <div className="mb-3 form-group row">
                    <label htmlFor="url" className="col-form-label col-sm-3">URL:</label>
                    <div className='col-sm-9 input-group'>
                      <span hidden={hideUrlPrefix()} className="input-group-text">{urlPrefix + "-"}</span>
                      <input disabled={processing || editMode} id='url' required pattern='[^. ]*' type="text" className='form-control' placeholder="Subdomain" value={url} onChange={evt => setUrl(evt.target.value)} />
                      <span className="input-group-text">{domain}</span>
                      <div className="invalid-feedback">
                        Required and cant contain "." or spaces
                      </div>
                    </div>
                  </div>
                  <div className="mb-3 form-group row">
                    <label htmlFor="securityGroup" className="col-form-label col-sm-3">Security Group:</label>
                    <div className='col-sm-9'>
                      <input disabled={processing} id='securityGroup' pattern='^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$' type="text" className='form-control' placeholder="Security Group" value={securityGroup} onChange={evt => setSecurityGroup(evt.target.value)} />
                      <div className="invalid-feedback">
                        Should either be blank or GUID
                      </div>
                    </div>
                  </div>

                  <div className="mb-3 form-group row">
                    <label className="form-check-label col-sm-3" htmlFor="groupCheck">Group:</label>
                    <div className="col-sm-9">
                      <input disabled={processing} type="checkbox" className="form-check-input" id="groupCheck" onChange={evt => groupsChanged(evt.target.checked)} checked={showGroups} />
                    </div>
                  </div>
                  <div className="mb-3 form-group row" hidden={!showGroups}>
                    <label className="form-check-label col-sm-3" htmlFor="auditoriumCheck">Auditorium:</label>
                    <div className="col-sm-9">
                      <input disabled={processing || !showGroups} type="checkbox" className="form-check-input" id="auditoriumCheck" checked={isAuditorium} onChange={evt => auditoriumChanged(evt.target.checked)} />
                    </div>
                  </div>
                  <div className="mb-3 form-group row" hidden={!showGroups || isAuditorium}>
                    <OverlayTrigger
                      placement='bottom-start'
                      overlay={<Tooltip>If this is set to true, patients will be able to see each others names in group calls.</Tooltip>}>
                      <label className="form-check-label col-sm-3" htmlFor="isShowPatientName">Show Patient Names: </label>
                    </OverlayTrigger>
                    <div className="col-sm-9">
                      <input disabled={processing} type="checkbox" className="form-check-input" id="isShowPatientName" onChange={evt => setIsShowPatientName(evt.target.checked)} checked={isShowPatientName} />
                    </div>
                  </div>
                </div>

              </div>
              <div hidden={showGroups} className='row'>
                <div className="col-sm">
                  <QuestionComponent
                    disabled={processing}
                    questions={questions}
                    questionLookups={questionLookups}
                    addQuestion={(question: Question) => addQuestions(questions => [...questions, question])}
                    removeQuestion={(question: Question) => removeQuestion(question)}
                  />
                </div>
              </div>
              <div className='row'>
                <div className="col-sm">
                  <h4>Basic Config</h4>
                  <div className="mb-3 form-group row">
                    <label htmlFor="feedbackUrl" className="col-form-label col-sm-3" >Feedback URL:</label>
                    <div className='col-sm-9'>
                      <input disabled={processing} id='feedbackUrl' type="url" className='form-control' placeholder="Feedback Url" value={feedbackUrl} onChange={evt => setFeedbackUrl(evt.target.value)} />
                    </div>
                  </div>
                  <div className="mb-3 form-group row">
                    <label htmlFor="serviceTitle" className="col-form-label col-sm-3" >Service Title:</label>
                    <div className='col-sm-9'>
                      <input disabled={processing} required id='serviceTitle' type="text" className='form-control' placeholder="Service Title" value={serviceTitle} onChange={evt => setServiceTitle(evt.target.value)} />
                    </div>
                  </div>
                  <div className="mb-3 form-group row">
                    <label htmlFor="waitingRoomLayout" className="col-form-label col-sm-3" >Waiting Room Layout:</label>
                    <div className='col-sm-9'>
                      <textarea disabled={processing} required id='waitingRoomLayout' className="form-textarea form-control" placeholder="Waiting Room Layout" rows={4} cols={500} value={waitingRoomLayout} onChange={evt => setWaitingRoomLayout(evt.target.value)} />
                    </div>
                  </div>
                  <div className="mb-3 form-group row">
                    <label htmlFor="recordingMessage" className="col-form-label col-sm-3" >Recording Message:</label>
                    <div className='col-sm-9'>
                      <textarea disabled={processing} required id='recordingMessage' className="form-textarea form-control" placeholder="Recording Message" rows={4} cols={500} value={recordingMessage} onChange={evt => setRecordingMessage(evt.target.value)} />
                    </div>
                  </div>
                  <div className="mb-3 form-group row">
                    <label htmlFor="privacyPolicyLink" className="col-form-label col-sm-3" >Privacy Policy Link:</label>
                    <div className='col-sm-9'>
                      <input disabled={processing} required id='privacyPolicyLink' type="url" className='form-control' placeholder="Privacy Policy Link" value={privacyPolicyLink} onChange={evt => setPrivacyPolicyLink(evt.target.value)} />
                      <div className="invalid-feedback">
                        Required and should be valid URL
                      </div>
                    </div>
                  </div>
                  <div className="mb-3 form-group row imgContainer">
                    <label htmlFor="roomImageUrl" className="col-form-label col-sm-3" >Room Image Url:</label>
                    <div className='col-sm-7'>
                      <input disabled={processing} required id='roomImageUrl' type="url" className='form-control' placeholder="Room Image Url" value={roomImageUrl} onChange={evt => setRoomImageUrl(evt.target.value)} />
                      <div className="invalid-feedback">
                        Required and should be valid URL
                      </div>
                    </div>
                    <div className='col-sm-2'><img className='img-fluid' src={roomImageUrl} alt='logo'></img></div>
                  </div>
                  <div className="mb-3 form-group row">
                    <label htmlFor="introductoryText" className="col-form-label col-sm-3" >Introductory Text:</label>
                    <div className='col-sm-9'>
                      <textarea disabled={processing} required id='introductoryText' className="form-textarea form-control" placeholder="Room Introductary Text" rows={4} cols={500} value={introductoryText} onChange={evt => setIntroductoryText(evt.target.value)} />
                    </div>
                  </div>
                  <div className="mb-3 form-group row">
                    <label className="form-check-label col-sm-3" htmlFor="confirmableMessageCheck">Show Confirmable Text:</label>
                    <div className="col-sm-9">
                      <input disabled={processing} type="checkbox" className="form-check-input" id="confirmableMessageCheck" onChange={evt => confirmableMessageChanged(evt.target.checked)} checked={showConfirmableMessage} />
                    </div>
                  </div>
                  <div className="mb-3 form-group row" hidden={!showConfirmableMessage}>
                    <label htmlFor="confirmableMessageText" className="col-form-label col-sm-3" >Confirmable Message Text:</label>
                    <div className='col-sm-9'>
                      <textarea disabled={processing} required={showConfirmableMessage} id='confirmableMessageText' className="form-textarea form-control" placeholder="Confirmable Message Text" rows={4} cols={500} value={confirmableMessageText} onChange={evt => setConfirmableMessageText(evt.target.value)} />
                    </div>
                  </div>
                  <div className="mb-3 form-group row">
                    <label htmlFor="beginConsultationText" className="col-form-label col-sm-3" >Begin Consultation Text:</label>
                    <div className='col-sm-9'>
                      <input disabled={processing} required id='beginConsultationText' className="form-control" value={beginConsultationText} onChange={evt => setBeginConsultationText(evt.target.value)} />
                    </div>
                  </div>
                  <div className="mb-3 form-group row">
                    <label htmlFor="teamsMeetingOrganiserId" className="col-form-label col-sm-3">Teams Meeting Organiser Id:</label>
                    <div className='col-sm-9'>
                      <input disabled={processing} id='teamsMeetingOrganiserId' required pattern='^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$' type="text" className='form-control' placeholder="Teams Meeting Organiser Id" value={teamsMeetingOrganiserId} onChange={evt => setTeamsMeetingOrganiserId(evt.target.value)} />
                      <div className="invalid-feedback">
                        Required and should be a GUID
                      </div>
                    </div>
                  </div>
                  <div className="mb-3 form-group row">
                    <label className="form-check-label col-sm-3" htmlFor="hideChat">Hide Chat:</label>
                    <div className="col-sm-9">
                      <input disabled={processing} type="checkbox" className="form-check-input" id="hideChat" checked={hideChat} onChange={evt => setHideChat(evt.target.checked)} />
                    </div>
                  </div>
                  <div className="mb-3 form-group row">
                    <label className="form-check-label col-sm-3" htmlFor="captureIP">Capture User IP Address:</label>
                    <div className="col-sm-9">
                      <input disabled={processing} type="checkbox" className="form-check-input" id="captureIP" checked={captureIP} onChange={evt => setCaptureIP(evt.target.checked)} />
                    </div>
                  </div>
                  <div className="mb-3 form-group row">
                    <label className="form-check-label col-sm-3" htmlFor="roomOnline">Room online</label>
                    <div className="col-sm-9">
                      <input disabled={processing} type="checkbox" className="form-check-input" id="roomOnline" checked={roomOnline} onChange={evt => setRoomOnline(evt.target.checked)} />
                    </div>
                  </div>
                </div>
              </div>

              <button disabled={processing} type="submit" className="btn btn-primary float-end ">Submit</button>
            </form>
          </>)}
    </>
  )
};


export default EditCreateRoomComponent;