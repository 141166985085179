import { useState, useEffect, ChangeEvent } from 'react';
import { useNavigate } from "react-router-dom";
import BasicRoomInfo from '../../models/basic-room-info';
import Tenant from '../../models/tenant';
import RoomStatus from './room-status-icons';
import TenantDropDown from './tenant-drop-down';
import { apiService } from '../../services/api-services';
import RoomRemoveModal from "../create-edit-room-page-components/room-remove-modal";
import { rolesService } from '../../services/roles-service';
import { toast } from 'react-toastify';
import { useAccount, useMsal } from '@azure/msal-react';

const RoomManagementComponent = () => {
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [numberOfLoads, setNumberOfLoads] = useState(0);
  const [basicRoomInfo, setRooms] = useState<BasicRoomInfo[]>([]);
  const [selectedTenant, setSelectedTenant] = useState<Tenant>({} as Tenant);
  const [roomSearchText, setRoomSearchText] = useState<string>('');
  const [tenants, setTenants] = useState<Tenant[]>([]);
  const [statusFilter, setStatusFilter] = useState('');
  const [showRemovePopUp, setShowRemovePopUp] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedRoom, setSelectedRoom] = useState({} as BasicRoomInfo);
  let roomsFound = false;  

  const goToCreatePage = () => {
    navigate("/create/" + selectedTenant.rowKey);
  };

  const goToEditPage = (id: string, url: string) => {
    navigate("/edit/" + id + "/" + url);
  };

  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  let isAdmin = rolesService.isAdmin(account);

  useEffect(() => {
    if (!loaded) {
      apiService
        .getTennants()
        .then(result => {
          setTenants(result.data);
        })
        .finally(() => { setLoaded(true); });
    }
  });

  if (!loaded || deleting) {
    return <div className="loader"></div>
  }
  const handleStatusfilterChange = (element: ChangeEvent<HTMLSelectElement>) => {
    setStatusFilter(element.target.value)
  }

  const tenantChanged = async (tenant: Tenant) => {
    if (tenant) {
      setSelectedTenant(tenant);
      await apiService.getPossibleRooms(tenant.rowKey).then(result => setRooms(result.data));
    }
  }

  const openRemoveModal = (value: boolean, index: number, room: BasicRoomInfo) => {
    setSelectedIndex(index);
    setSelectedRoom(room);
    setShowRemovePopUp(value);
  };

  const closeRemoveModal = (value: boolean) => {
    setShowRemovePopUp(false);
  };

  const removeRoom = (room: BasicRoomInfo) => {
    setDeleting(true);
    apiService.deleteRoom(room.partitionKey, room.rowKey)
      .then((result) => {
        toast.success("Room has been deleted.")
        setRoomSearchText("");
        setStatusFilter("");
        setLoaded(false);
      })
      .finally(() => {
        setDeleting(false);
      });
    setShowRemovePopUp(false);
  };

  return (
    <>
      <div className="col-sm-6 intro">
        <h3>Room Management</h3>
      </div>
      <br />
      <div className='row'>
        <div className='col-sm'>
          <TenantDropDown items={tenants} onSelectionChanged={(tenant: Tenant) => tenantChanged(tenant)} />
        </div>
        <div className='col-sm' >
          <button type="submit" disabled={!selectedTenant?.rowKey} className="btn btn-primary" onClick={value => goToCreatePage()}>Create New</button>
        </div>
        <div className='col-sm'>
          <input type="text" className="form-control float-end" placeholder="Search rooms..." aria-label="Search rooms" onChange={value => setRoomSearchText(value.currentTarget.value)
          } />
        </div>
        <div className='col-sm' >
          <select className="form-select"
            value={statusFilter}
            onChange={(event) => handleStatusfilterChange(event)} >
            <option defaultValue="All">All</option>
            <option value="Completed">Completed</option>
            <option value="Uncompleted">Uncompleted</option>
          </select>
        </div>

      </div >
      <div className='row'>
        <table className="table">
          <thead>
            <tr>
              <th scope="col">URL</th>
              <th scope="col">Tenant Name</th>
              <th scope="col">Room Name</th>
              <th scope="col">Status</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {basicRoomInfo.filter(basicRoomInfo => {
              if (selectedTenant && selectedTenant?.rowKey) {
                return basicRoomInfo.basicTenantInfo?.adminPortalTitle === selectedTenant?.adminPortalTitle;
              }

              return true;
            })
              .filter(basicRoomInfo => {
                if (statusFilter === "Completed") {
                  return basicRoomInfo.roomStatus !== "Created" && basicRoomInfo.roomStatus !== "EasyDnsDone";
                }
                if (statusFilter === "Uncompleted") {
                  return basicRoomInfo.roomStatus === "Created" || basicRoomInfo.roomStatus === "EasyDnsDone";
                }
                return true;
              })
              .filter(basicRoomInfo => {
                return roomSearchText ? basicRoomInfo.name.toLowerCase()?.includes(roomSearchText.toLowerCase()) || basicRoomInfo.rowKey.toLowerCase()?.includes(roomSearchText.toLowerCase()) : true;
              })
              .map((row, i) => {
                roomsFound = true;
                return (
                  <tr key={i}>
                    <td><a href={"https://" + row.rowKey} target="_">{row.rowKey}</a></td>
                    <td>{row.basicTenantInfo?.adminPortalTitle ? row.basicTenantInfo?.adminPortalTitle : "No Tenant"}</td>
                    <td>{row.name}</td>
                    <td><RoomStatus status={row.roomStatus} /></td>
                    <td>
                      <button type="submit" disabled={!row?.basicTenantInfo?.rowKey} className="btn btn-primary" onClick={value => goToEditPage(row.partitionKey, row.rowKey)}>Edit</button>&nbsp;
                      {isAdmin && <button type="submit" disabled={!row?.basicTenantInfo?.rowKey} className="btn btn-danger" onClick={() => openRemoveModal(true, i, row)}>Delete</button>}
                    </td>
                  </tr>)
              })}
          </tbody>
        </table>
        {!roomsFound && <div className="text-center"><p>No rooms found</p></div>}
      </div>
      <RoomRemoveModal show={showRemovePopUp} close={() => closeRemoveModal(false)} removeRoom={(value: BasicRoomInfo) => removeRoom(value)} index={selectedIndex} selectedRoom={selectedRoom} />
    </>
  )
};

export default RoomManagementComponent;